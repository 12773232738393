import { store } from '../../index'
import { authenticateUser, configureAuth, currentUserAttributes, singOut, completeNewPassword } from '../amplify'

const asyncAction = (action) => {
    return {
        [`${action}_ATTEMPT`]:  `${action}_ATTEMPT`,
        [`${action}_SUCCESS`]:  `${action}_SUCCESS`,
        [`${action}_FAIL`]:  `${action}_FAIL`,
    }
}

export const ACTION = {
    UPDATE_EMAIL: "UPDATE_EMAIL",
    UPDATE_PASSWORD: "UPDATE_PASSWORD",
    ...asyncAction("LOGIN"),
    LOGOUT: "LOGOUT",
    UPDATE_REMEMBER_ME: "UPDATE_REMEMBER_ME",
    CANCEL_PASSWORD_UPDATE: "CANCEL_PASSWORD_UPDATE",
    REQUIRE_PASSWORD_UPDATE: "REQUIRE_PASSWORD_UPDATE",
    CLEAR_LOGIN_ERRORS: "CLEAR_LOGIN_ERRORS"
}

// ================================== actions ==================================

export const updateEmail = (email) => {
    return {
        type: ACTION.UPDATE_EMAIL,
        email
    }
}

export const updatePassword = (password) => {
    return {
        type: ACTION.UPDATE_PASSWORD,
        password
    }
}

const loginAttempt = () => {
    return {
        type: ACTION.LOGIN_ATTEMPT,
    }
}

const loginSuccess = (userInfo) => {
    return {
        type: ACTION.LOGIN_SUCCESS,
        userInfo
    }
}

const loginFail = (error) => {
    return {
        type: ACTION.LOGIN_FAIL,
        error
    }
}

const logOut = () => {
    return {
        type: ACTION.LOGOUT,
    }
}

export const updateRememberMe = (checked) => {
    return {
        type: ACTION.UPDATE_REMEMBER_ME,
        checked
    }
}

export const cancelPasswordUpdate = () => {
    return {
        type: ACTION.CANCEL_PASSWORD_UPDATE
    }
}

const requirePasswordUpdate = (cognitoUser) => {
    return {
        type: ACTION.REQUIRE_PASSWORD_UPDATE,
        cognitoUser
    }
}

export const clearLoginError = () => {
    return {
        type: ACTION.CLEAR_LOGIN_ERRORS
    }
}

// ================================== thunks ==================================

export const attemptToLoginFromStorage = async () => {
    const { dispatch } = store
    dispatch(loginAttempt())
    configureAuth()
    try {
        const { validSession, userInfo, error} = await currentUserAttributes()
        if(validSession){
            dispatch(loginSuccess(userInfo))
        } else {
            dispatch(loginFail(error))
        }
        
    } catch (e) {
        dispatch(loginFail(e))
    }
}

export const loginUser = () => async (dispatch, getState) => {
    const { email, password, rememberMe } = getState().user

    await loginAction( email, password, rememberMe, dispatch )
}

const loginAction = async (email, password, rememberMe, dispatch) => {
    dispatch(loginAttempt())
    const { loggedIn, newPasswordRequired, error, CognitoUser } = await authenticateUser(email, password, rememberMe)
    if(loggedIn) {
        const { userInfo } = await currentUserAttributes()
        dispatch(loginSuccess(userInfo))
    } else if (newPasswordRequired) {
        dispatch(requirePasswordUpdate(CognitoUser))
    } else {
        dispatch(loginFail(error))
    }
}

export const createNewPasswordWhenForced = () => async (dispatch, getState) => {
    const { email, password, cognitoUser, rememberMe } = getState().user

    dispatch(loginAttempt())
    const { success, error } = await completeNewPassword(email, password, cognitoUser)

    if(success) {  
        await loginAction( email, password, rememberMe, dispatch )
    } else {
        dispatch(loginFail(error))
    }

    
}

export const logOutUser = () => async dispatch => {
    await singOut()
    dispatch(logOut())
}