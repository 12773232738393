import { applyMiddleware, createStore, compose } from 'redux'
import { createLogger } from 'redux-logger'
import ThunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'

const middleWareApplier = applyMiddleware(ThunkMiddleware, createLogger())

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function initStore() {
    const store = createStore(
        rootReducer,
        composeEnhancers(
            middleWareApplier
        )
    )

    return store
}