import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import initStore from './redux/store'
import { attemptToLoginFromStorage } from './redux/actions/user'
import App from './components/App'
import reportWebVitals from './reportWebVitals';

export const store = initStore()



const initApp = async () => {
	await attemptToLoginFromStorage()

	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<App />
			</Provider>
		</React.StrictMode>,
		document.getElementById('root')
	);
}

initApp()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
