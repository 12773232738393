import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/actions/user";

const onSignOut = (dispatch) => (e) => {
  e.preventDefault();
  dispatch(logOutUser());
};

function Header() {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.user);
  return (
    <div className="bg-white w-full">
      <ul className="flex">
        <li className="m-6">
          <Link to="/">
            <img
              src="../assets/number-patio-icon.png"
              alt="Number Patio Icon"
            />
          </Link>
        </li>
        {!loggedIn && (
          <>
            <li className="m-6">
              <Link className="text-blue-500 hover:text-blue-800" to="/login">
                Log In
              </Link>
            </li>
            <li className="m-6">
              <Link className="text-blue-500 hover:text-blue-800" to="/signup">
                Sign Up
              </Link>
            </li>
          </>
        )}
        <li className="m-6 flex justify-center">
          <Link className="text-blue-500 hover:text-blue-800" to="/about">
            About
          </Link>
        </li>
        {loggedIn && (
          <li className="m-6">
            <button
              className="text-blue-500 hover:text-blue-800"
              onClick={onSignOut(dispatch)}
            >
              {" "}
              Sign Out{" "}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Header;
