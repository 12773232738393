import { ACTION } from '../actions/user'

const initialState = {
    username: 'Matthew',
    userInfo: {},
    email: '',
    password: '',
    loggedIn: false,
    loggingIn: false,
    loginError: null,
    rememberMe: false,
    resetPassword: false,
    cognitoUser: null,
}

const createState = (state) => (updateState) => {
    return {
        ...state,
        ...updateState
    }
}

const user = (state = initialState, action ) => {
    const updateCurrentState = createState(state);
    switch (action.type) {
        case ACTION.UPDATE_EMAIL: {
            return updateCurrentState({
                email: action.email
            })
        }
        case ACTION.UPDATE_PASSWORD: {
            return updateCurrentState({
                password: action.password
            })
        }
        case ACTION.LOGIN_ATTEMPT: {
            return updateCurrentState({
                loggingIn: true,
                loginError: null,
            })
        }
        case ACTION.LOGIN_SUCCESS: {
            return updateCurrentState({
                loggingIn: false,
                loggedIn: true,
                userInfo: action.userInfo,
                loginError: null,
            })
        }
        case ACTION.LOGIN_FAIL: {
            return updateCurrentState({
                loggingIn: false,
                loggedIn: false,
                loginError: action.error
            })
        }
        case ACTION.LOGOUT: {
            return updateCurrentState({
                loggedIn: false,
            })
        }
        case ACTION.UPDATE_REMEMBER_ME: {
            return updateCurrentState({
                rememberMe: action.checked,
            })
        }
        case ACTION.CANCEL_PASSWORD_UPDATE: {
            return updateCurrentState({
                resetPassword: false,
                email: '',
                password: '',
                cognitoUser: null,
            })
        }
        case ACTION.REQUIRE_PASSWORD_UPDATE: {
            return updateCurrentState({
                resetPassword: true,
                password: '',
                cognitoUser: action.cognitoUser,
            })
        }
        case ACTION.CLEAR_LOGIN_ERRORS: {
            return updateCurrentState({
                loginError: null
            })
        }
        default: {
            return updateCurrentState({});
        }
    }
}

export default user;