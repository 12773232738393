import Auth from '@aws-amplify/auth';
import { getUserPoolWebClientId, getAWSRegion, getUserPoolId } from '../environment'

export const configureAuth = (useSessionStorage) => {
    
    const config = {
        region: getAWSRegion(),
        userPoolId: getUserPoolId(),
        userPoolWebClientId: getUserPoolWebClientId(),
        mandatorySignIn: false,
        // storage: new MyStorage()
    }

    if(useSessionStorage){
        config.storage = sessionStorage
    }

    Auth.configure({ Auth: config });
}

export const currentUserAttributes = async () => {
    try {
        const user = await Auth.currentUserInfo()
        const session = await Auth.currentSession()
        return {
            userInfo: user.attributes,
            validSession: session.isValid(),
            error: null
        }
    } catch (e) {
        return {
            userInfo: null,
            validSession: null,
            error: e
        }
    }
    
}

export const authenticateUser = async (email, password, rememberMe) => {
    configureAuth(!rememberMe)
    email = email.toLowerCase()
    try {
        const user = await Auth.signIn(email, password)

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return {loggedIn: false, CognitoUser: user, newPasswordRequired: true, error: null}
        }
        // const data = await currentUserAttributes()
        return { loggedIn: true, CognitoUser: user, newPasswordRequired: false, error: null }
    } catch (error) {
        return { loggedIn: false, CognitoUser: null, newPasswordRequired: false, error }
    }
}

export const completeNewPassword = async (email, password, cognitoUser) => {
    try {
        Auth.completeNewPassword(cognitoUser, password, {
            email,
        });
        return { success: true, error: null }
    } catch (error) {
        return { success: false, error }
    }
    
}

export const singOut = async () => {
    await Auth.signOut();
}