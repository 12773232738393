import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "../Header";
import Loading from "../Loading";

// this will allow React to only deliver these pages when they're navigated to client side
// this allow us to split the application into smaller deliveries
const Home = lazy(() => import("../Home"));
const About = lazy(() => import("../About"));
const Login = lazy(() => import("../Login"));
// const Signup = lazy(() => import("../Signup"));
const NotFound = lazy(() => import("../NotFound"));

const ManageProperties = lazy(() => import("../ManageProperties"));

function AppRouter() {
    return ( 
        <Router>
            <Header />
            {/* the fallback in <suspense> tag is displayed while the components are being lazily loaded */}
            <Suspense fallback={Loading()}>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/about" component={About}/>
                    <Route path="/login" component={Login}/>
                    {/* <Route path="/signup" component={Signup}/> */}
        			<Route path="/properties/manage" component={ManageProperties} />

                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </Router>
    );
}

export default AppRouter;
